<template>
    <div class="container" style="margin-top:10px;">
        <!-- Loading Overlay -->
        <div v-if="loading" class="loading-overlay">
            <div class="loading-content text-center">
                <div class="spinner-border text-primary mb-3" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <p class="text-primary">处理中，请稍候...<br>如果用户较多，可能需要 5-10 分钟</p>
            </div>
        </div>

        <!-- Main Form Card -->
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">{{ $t('Send') }}</h5>
            </div>
            <div class="card-body">
                <form @submit.prevent="handleSubmit" enctype="multipart/form-data" :class="{ 'loading-form': loading }">
                    <!-- Title Input -->
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="Title" name="Title" v-model="Title" required>
                        <label for="Title">{{ $t('Title') }}</label>
                    </div>

                    <!-- Image Upload -->
                    <div class="mb-3">
                        <label class="form-label">{{ $t('Image') }}</label>
                        <div class="input-group">
                            <span class="input-group-text">
                                <i class="bi bi-image"></i>
                            </span>
                            <input type="file" class="form-control" ref="fileInput" @change="handleFileUpload"
                                id="Image" name="Image" accept="image/png, image/jpeg, image/jpg, image/gif">
                        </div>
                        <div class="form-text">支持的格式：PNG, JPEG, JPG, GIF</div>
                    </div>

                    <!-- Message Content -->
                    <div class="form-floating mb-4">
                        <textarea class="form-control" id="Message" name="Message" v-model="Message"
                            style="height: 150px" required></textarea>
                        <label for="Message">{{ $t('Message') }}</label>
                    </div>

                    <!-- Schedule Options -->
                    <div class="card mb-4">
                        <div class="card-header">
                            发送时间设置
                        </div>
                        <div class="card-body">
                            <div class="mb-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" v-model="Schedule" name="Schedule"
                                        id="Now" value="Now" checked>
                                    <label class="form-check-label" for="Now">
                                        {{ $t('Now') }}
                                    </label>
                                </div>
                            </div>
                            <div class="alert alert-info">
                                * 暂时只能使用即时发送（Currently only available for Now）
                            </div>
                        </div>
                    </div>

                    <!-- Submit Button -->
                    <div class="d-grid gap-2">
                        <button type="submit" class="btn btn-primary btn-lg" :disabled="loading">
                            <i class="bi bi-send me-2"></i>
                            {{ loading ? $t('Sending') : $t('Send') }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'MessageSend',
    data() {
        return {
            Title: '',
            Message: '',
            Schedule: 'Now',
            Time: 0,
            Image: null,
            loading: false,
            selectedDays: {},
            Weeklys: [
                ['Sunday', this.$t("Sunday")],
                ['Monday', this.$t("Monday")],
                ['Tuesday', this.$t("Tuesday")],
                ['Wednesday', this.$t("Wednesday")],
                ['Thursday', this.$t("Thursday")],
                ['Friday', this.$t("Friday")],
                ['Saturday', this.$t("Saturday")]
            ]
        };
    },
    methods: {
        handleFileUpload(event) {
            this.Image = event.target.files[0];
        },

        async handleSubmit() {
            try {
                this.loading = true;
                const formData = new FormData();
                formData.append('Title', this.Title);
                formData.append('Message', this.Message);
                formData.append('Schedule', this.Schedule);
                if (this.Image) {
                    formData.append('Image', this.Image);
                }

                const response = await axios.post(
                    'https://ms.app96.cc/VueAPI/MessageSend.php?AppID=' + localStorage.getItem('AppID'),
                    formData
                );

                if (response.data.success) {
                    this.$router.push('/messages');
                    // eslint-disable-next-line
                    Swal.fire({
                        title: '成功',
                        text: '消息已发送',
                        icon: 'success'
                    });
                }
            } catch (error) {
                console.error('Error:', error);
                // eslint-disable-next-line
                Swal.fire({
                    title: '错误',
                    text: '发送失败',
                    icon: 'error'
                });
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-floating>.form-control {
    height: calc(3.5rem + 2px);
    line-height: 1.25;
}

.form-floating>textarea.form-control {
    height: 150px;
}

.card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-primary {
    transition: all 0.3s ease;
}

.btn-primary:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.form-text {
    color: #6c757d;
    font-size: 0.875rem;
}

.alert {
    margin-bottom: 0;
}
</style>