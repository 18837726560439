<template>
    <div class="container" style="margin-top:10px;">
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">{{ $t('ChangePassword') }}</h5>
            </div>
            <div class="card-body">
                <form @submit.prevent="handleSubmit">
                    <div class="mb-3">
                        <label class="form-label">{{ $t('CurrentPassword') }}</label>
                        <input type="password" class="form-control" v-model="form.OldPassword" required>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">{{ $t('NewPassword') }}</label>
                        <input type="password" class="form-control" v-model="form.NewPassword" required>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">{{ $t('ConfirmPassword') }}</label>
                        <input type="password" class="form-control" v-model="form.confirmPassword" required>
                    </div>
                    <button type="submit" class="btn btn-primary" :disabled="loading">
                        {{ loading ? $t('Updating') : $t('Update') }}
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';

export default {
    name: 'ChangePassword',
    setup() {
        const loading = ref(false);
        const form = ref({
            OldPassword: '',
            NewPassword: '',
            confirmPassword: ''
        });

        const handleSubmit = async () => {
            if (form.value.NewPassword !== form.value.confirmPassword) {
                // eslint-disable-next-line
                Swal.fire({
                    title: 'Error',
                    text: 'New passwords do not match',
                    icon: 'error'
                });
                return;
            }

            try {
                loading.value = true;
                const formData = new FormData();
                formData.append('OldPassword', form.value.OldPassword);
                formData.append('NewPassword', form.value.NewPassword);

                const response = await axios.post(
                    'https://bo.app96.cc/VueAPI/ChangePassword.php?AppID=' + localStorage.getItem('AppID'),
                    formData
                );

                if (response.data.error) {
                    throw new Error(response.data.message);
                }

                // eslint-disable-next-line
                Swal.fire({
                    title: 'Success',
                    text: 'Password updated successfully',
                    icon: 'success'
                });

                form.value = {
                    OldPassword: '',
                    NewPassword: '',
                    confirmPassword: ''
                };
            } catch (error) {
                console.error('Error:', error);
                // eslint-disable-next-line
                Swal.fire({
                    title: 'Error',
                    text: error.message || 'Failed to update password',
                    icon: 'error'
                });
            } finally {
                loading.value = false;
            }
        };

        return {
            form,
            loading,
            handleSubmit
        };
    }
};
</script>