<template>
  <div>

    <!-- 如果sessionId不存在，渲染Login组件 -->
    <Login v-if="!sessionId" />
    <!-- 如果sessionId存在，渲染Sidebar和router-view组件 -->
    <div v-else>
      <!-- <p>{{ $t('hello') }}</p> -->
      <sidebar v-if="Mysidebar" :is-collapsed="isSidebarCollapsed" @toggle-sidebar="toggleSidebar"></sidebar>
      <div class="Main" :class="{
        'with-sidebar': Mysidebar,
        'with-collapsed-sidebar': isSidebarCollapsed
      }">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import Sidebar from "@/components/MySidebar.vue";
import Login from "@/components/MyLogin.vue";

export default {
  components: {
    Sidebar,
    Login,
  },

  data() {
    return {
      sessionId: localStorage.getItem('AppID'), // 从localStorage获取sessionId
      Mysidebar: false,
      isSidebarCollapsed: false
    };
  },
  mounted() {
    if (this.sessionId != null) {
      this.FirstTime();
    }

  },
  methods: {
    FirstTime() {
      this.Mysidebar = true
      // axios.post('https://bo.app96.cc/VueAPI/FirstLogin.php?AppID=' + this.sessionId)
      //   .then(response => {
      //     if (response.data.FirstTime) {
      //       this.$router.push("/CP");
      //       this.Mysidebar = false
      //     } else {
      //       this.Mysidebar = true
      //     }
      //   });
    },
    toggleSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed;
    }
  }
};
</script>

<style scoped>
.Main {
  transition: margin-left 0.3s;
  position: relative;
  z-index: 1;
}

.Main.with-sidebar {
  margin-left: 200px;
}

.Main.with-collapsed-sidebar {
  margin-left: 50px;
}
</style>