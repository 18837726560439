<template>
    <div class="login-container">
        <div class="login-box">
            <h2>登录</h2>
            <form @submit.prevent="handleLogin">
                <div class="form-group">
                    <input type="text" v-model="loginForm.username" placeholder="用户名" required>
                </div>
                <div class="form-group">
                    <input type="password" v-model="loginForm.password" placeholder="密码" required>
                </div>
                <button type="submit" :disabled="loading">
                    {{ loading ? '登录中...' : '登录' }}
                </button>
                <div v-if="error" class="error-message">
                    {{ error }}
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'MyLogin',
    data() {
        return {
            loginForm: {
                username: '',
                password: ''
            },
            loading: false,
            error: null
        }
    },
    methods: {
        async handleLogin() {
            this.loading = true;
            this.error = null;

            try {
                const formData = new FormData();
                formData.append('Account', this.loginForm.username); // Assuming this.Title is bound to your input field
                formData.append('Password', this.loginForm.password); // Assuming this.Image is bound to your file input field

                axios.post('https://bo.app96.cc/VueAPI/Login.php', formData)
                    .then(response => {
                        // 处理响应
                        console.log(response.data);
                        if (response.data.success) {
                            localStorage.setItem('AppID', response.data.AppID);
                            // const firstLoginResponse = axios.get(
                            //     'https://bo.app96.cc/VueAPI/FirstLogin.php?AppID=' + response.data.AppID
                            // );

                            // if (firstLoginResponse.data.FirstTime) {
                            //     this.$router.push("/CP");
                            // } else {
                            window.location.reload();
                            // }
                        } else {
                            this.error = response.data.error || '登录失败';
                        }
                        // const firstLoginResponse = axios.get(
                        //     'https://bo.app96.cc/VueAPI/FirstLogin.php',
                        //     null,
                        //     {
                        //         params: {
                        //             AppID: response.data.AppID
                        //         }
                        //     }
                        // );

                        // if (firstLoginResponse.data.FirstTime) {
                        //     this.$router.push("/CP");
                        // } else {
                        //     window.location.reload();
                        // }
                    })
                    .catch(error => {
                        // 处理错误
                        console.log(error);
                    });


            } catch (err) {
                this.error = '网络错误，请稍后重试';
                console.error('Login error:', err);
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.login-box {
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-width: 300px;
}

.form-group {
    margin-bottom: 15px;
}

input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

button {
    width: 100%;
    padding: 10px;
    background: #42b983;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:disabled {
    background: #9be0c0;
    cursor: not-allowed;
}

button:hover:not(:disabled) {
    background: #3aa876;
}

.error-message {
    color: #dc3545;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}
</style>