import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import i18n from "./i18n";

const app = createApp(App);
app.use(router);
app.use(i18n);

// 设置默认语言
const savedLocale = localStorage.getItem("locale") || "zh";
i18n.global.locale.value = savedLocale;

app.config.globalProperties.$axios = axios;

app.mount("#app");
