<template>
    <div class="container" style="margin-top:10px;">
        <div class="card text-bg-secondary">
            <div class="card-header">
                Contact Seach
            </div>
            <div class="card-body">
                <div class="input-group mb-3">
                    <input type="month" v-model="month" class="form-control">
                    <button class="btn btn-primary" type="button" @click="fetchData('ct')">NewSeach</button>
                    <button class="btn btn-primary" type="button" @click="fetchData('bo')">OldSeach</button>

                </div>
            </div>
        </div>
        <br>
        <div class="card border-success">
            <div class="card-header">
                Contact
            </div>
            <div class="card-body">
                <p v-if="!loading && !exporting">
                    <input type="search" v-model="filterName" class="form-control" :placeholder="$t('Search')">
                </p>
                <div class="container" style="margin-top:10px;">
                    <div v-if="loading" class="text-primary" disabled>
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </div>
                    <div v-if="!loading">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>No.</th>
                                    <th>{{ $t('Download') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in filteredItems" :key="item.ID">
                                    <td>{{ (currentPage - 1) * pageSize + index + 1 }}</td>
                                    <td>{{ item.ID }}</td>
                                    <td>
                                        <button class="btn btn-primary btn-sm" @click="UpdateMessage(item.ID)">
                                            {{ $t('Download') }}
                                        </button>
                                        <button class="btn btn-danger btn-sm ms-2" @click="DeleteMessage(item.ID)">
                                            {{ $t('Delete') }}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <nav v-if="totalPages > 1">
                            <ul class="pagination justify-content-center">
                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                    <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">上一页</a>
                                </li>
                                <li class="page-item" v-for="page in displayedPages" :key="page"
                                    :class="{ active: page === currentPage }">
                                    <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                                </li>
                                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                    <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">下一页</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
    name: 'ContactNoted',
    setup() {
        const router = useRouter();
        const month = ref(new Date().toISOString().slice(0, 7));
        const Items = ref([]);
        const loading = ref(false);
        const filterName = ref('');
        const SQLData = ref('bo');
        const currentPage = ref(1);
        const totalPages = ref(1);
        const pageSize = ref(50);

        const displayedPages = computed(() => {
            const pages = [];
            const maxDisplayed = 5;
            let start = Math.max(1, currentPage.value - 2);
            let end = Math.min(totalPages.value, start + maxDisplayed - 1);

            if (end - start + 1 < maxDisplayed) {
                start = Math.max(1, end - maxDisplayed + 1);
            }

            for (let i = start; i <= end; i++) {
                pages.push(i);
            }
            return pages;
        });

        const fetchData = async (u, page = 1) => {
            try {
                SQLData.value = u;
                loading.value = true;
                currentPage.value = page;

                const apiUrl = `https://${u}.app96.cc/VueAPI/ContactNotedMonth.php?AppID=${localStorage.getItem('AppID')}&month=${month.value}&page=${page}&limit=${pageSize.value}`;
                console.log(apiUrl);

                const response = await axios.get(apiUrl);
                Items.value = response.data.items;
                totalPages.value = response.data.totalPages;

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                loading.value = false;
                filterName.value = '';
            }
        };

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                fetchData(SQLData.value, page);
            }
        };

        const UpdateMessage = (id) => {
            const apiUrl = `https://${SQLData.value}.app96.cc/VueAPI/ContactNotedCSV.php?AppID=${localStorage.getItem('AppID')}&ID=${id}`;
            window.open(apiUrl, '_blank');
        };

        const DeleteMessage = (id) => {
            // eslint-disable-next-line
            Swal.fire({
                title: 'Are You Sure Delete this Contact?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        loading.value = true;
                        const apiUrl = `https://${SQLData.value}.app96.cc/VueAPI/ContactNotedDelete.php?AppID=${localStorage.getItem('AppID')}&ID=${id}`;
                        await axios.get(apiUrl);

                        // 从列表中移除已删除的项
                        Items.value = Items.value.filter(item => item.ID !== id);

                        // eslint-disable-next-line
                        Swal.fire(
                            'Delete!',
                            'This is deleted',
                            'success'
                        );
                    } catch (error) {
                        console.error('Error deleting:', error);
                    } finally {
                        loading.value = false;
                    }
                }
            });
        };

        const goToSend = () => {
            router.push('/send');
        };

        return {
            month,
            Items,
            loading,
            filterName,
            SQLData,
            currentPage,
            totalPages,
            pageSize,
            displayedPages,
            filteredItems: computed(() => Items.value),
            fetchData,
            changePage,
            UpdateMessage,
            DeleteMessage,
            goToSend,
        };
    }
};
</script>

<style scoped>
.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}
</style>
