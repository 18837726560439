<template>
    <div class="sidebar" :class="{ 'collapsed': isCollapsed }">
        <div class="toggle-btn" @click="toggleSidebar">
            <i class="bi" :class="isCollapsed ? 'bi-chevron-right' : 'bi-chevron-left'"></i>
        </div>
        <nav>
            <router-link to="/contact" :title="$t('Contact')">
                <i class="bi bi-people-fill"></i>
                <span v-show="!isCollapsed">{{ $t('Contact') }}</span>
            </router-link>
            <router-link to="/messages" :title="$t('Messages')">
                <i class="bi bi-chat-dots-fill"></i>
                <span v-show="!isCollapsed">{{ $t('Messages') }}</span>
            </router-link>
            <router-link to="/password" :title="$t('ChangePassword')">
                <i class="bi bi-key-fill"></i>
                <span v-show="!isCollapsed">{{ $t('ChangePassword') }}</span>
            </router-link>

            <!-- Language Selector -->
            <div class="language-selector" :class="{ 'collapsed': isCollapsed }">
                <select class="form-select" :value="currentLocale" @change="changeLanguage"
                    :title="isCollapsed ? 'Language' : ''">
                    <option value="zh">中文</option>
                    <option value="en">English</option>
                </select>
            </div>

            <!-- Logout Button -->
            <button class="logout-btn" @click="handleLogout" :title="$t('Logout')">
                <i class="bi bi-box-arrow-right"></i>
                <span v-show="!isCollapsed">{{ $t('Logout') }}</span>
            </button>
        </nav>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
    name: 'MySidebar',
    props: {
        isCollapsed: {
            type: Boolean,
            default: false
        }
    },
    emits: ['toggle-sidebar'],
    setup(props, { emit }) {
        const router = useRouter();
        const { locale } = useI18n();
        const currentLocale = ref(localStorage.getItem('locale') || 'zh');

        const toggleSidebar = () => {
            emit('toggle-sidebar');
        };

        const changeLanguage = (event) => {
            const newLocale = event.target.value;
            locale.value = newLocale;
            currentLocale.value = newLocale;
            localStorage.setItem('locale', newLocale);
        };

        const handleLogout = () => {
            // eslint-disable-next-line
            Swal.fire({
                title: currentLocale.value === 'zh' ? '确认退出？' : 'Are you sure?',
                text: currentLocale.value === 'zh' ? '您将退出登录！' : 'You will be logged out!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: currentLocale.value === 'zh' ? '是的，退出！' : 'Yes, logout!',
                cancelButtonText: currentLocale.value === 'zh' ? '取消' : 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.removeItem('AppID');
                    router.push('/');
                    window.location.reload();
                }
            });
        };

        return {
            currentLocale,
            changeLanguage,
            handleLogout,
            toggleSidebar
        };
    }
};
</script>

<style scoped>
.sidebar {
    width: 200px;
    height: 100vh;
    background-color: #2c3e50;
    padding: 20px;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    z-index: 1000;
    overflow: visible;
}

.sidebar.collapsed {
    width: 50px;
    padding: 20px 10px;
}

.toggle-btn {
    position: absolute;
    right: -12px;
    top: 20px;
    background: #42b983;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    z-index: 1001;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

nav {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    position: relative;
    z-index: 1000;
}

a {
    color: white;
    text-decoration: none;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
}

.collapsed a {
    padding: 10px 0;
    justify-content: center;
}

a:hover,
a.router-link-active {
    background-color: #42b983;
}

.language-selector {
    margin-top: auto;
    padding: 10px;
    transition: all 0.3s ease;
}

.language-selector.collapsed {
    padding: 10px 0;
}

.form-select {
    background-color: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.collapsed .form-select {
    width: 30px;
    text-align: center;
    padding: 5px;
}

.form-select:focus {
    border-color: #42b983;
    box-shadow: 0 0 0 0.25rem rgba(66, 185, 131, 0.25);
}

.form-select option {
    background-color: #2c3e50;
    color: white;
}

.logout-btn {
    margin-top: 10px;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
}

.collapsed .logout-btn {
    padding: 10px 0;
    justify-content: center;
}

.logout-btn:hover {
    background-color: #dc3545;
    border-color: #dc3545;
}

i {
    font-size: 1.1rem;
}
</style>