import { createRouter, createWebHistory } from "vue-router";
import AppContact from "../views/AppContact.vue";
import Message from "../views/MessageHistory.vue";
import MessageSend from "../views/MessageSend.vue";
import ChangePassword from "../views/ChangePassword.vue";

const routes = [
  {
    path: "/",
    redirect: "/contact",
  },
  {
    path: "/contact",
    name: "Contact",
    component: AppContact,
  },

  {
    path: "/messages",
    name: "Message",
    component: Message,
  },
  {
    path: "/send",
    name: "MessageSend",
    component: MessageSend,
  },
  {
    path: "/password",
    name: "ChangePassword",
    component: ChangePassword,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
