<template>
    <div>
        <button class="btn btn-success" type="button" @click="goToSend">
            <i class="bi bi-plus"></i> {{ $t('Send') }}
        </button>
        <div class="my-3 p-3 bg-body rounded shadow-sm">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center nav-tabs">
                <div role="tablist">
                    <h6 class="border-bottom pb-2 mb-0">{{ $t("History") }}</h6>
                </div>
                <div>
                    <i class="fa-solid fa-arrows-rotate" role="button" @click="callMessage"></i>
                </div>
            </div>


            <div v-for="item in Items" :key="item.id">
                <div class="d-flex text-muted pt-3 border-bottom">
                    <svg class="bd-placeholder-img flex-shrink-0 me-2 rounded" width="32" height="32"
                        xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32"
                        preserveAspectRatio="xMidYMid slice" focusable="false">

                        <rect width="100%" height="100%" v-bind:fill="item.Color" /><text x="50%" y="50%"
                            v-bind:fill="item.Color" dy=".3em">32x32</text>
                    </svg>
                    <div class="pb-3 mb-0 small lh-sm w-100">
                        <strong class="d-block text-gray-dark">{{ item.Title }}</strong>
                        <img v-if="item.Image !== null" :src="item.Image" class="rounded mx-auto d-block w-50">
                        <div class="mt-2 mb-3">{{ item.Message }}</div>

                        <div class="hstack gap-2">
                            <div class="p-2"> {{ $t("Send") }}：{{ item.Count }}</div>
                            <div class="p-2 ms-auto">{{ $t("Time") }}：{{ item.Date }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <small class="d-block text-end mt-3">
                <i class="fa-solid fa-chevron-down" role="button" @click="loadMoreItems"> {{ $t("More") }}</i>
            </small>
        </div>

    </div>
</template>
<script>
import axios from 'axios';

export default {

    data() {
        return {
            Limit: 5,
            Items: [],
        };
    },
    created() {
        this.callMessage()
        // const eventBus = inject('eventBus');

        // // 在组件中使用 eventBus
        // eventBus.onEvent('trigger-call-message', (data) => {
        //     console.log('Received data:', data);
        // });
        // EventBus.$on("trigger-call-message", this.callMessage);
    },
    methods: {
        callMessage() {
            axios.get('https://ms.app96.cc/VueAPI/MessageHistory.php?AppID=' + localStorage.getItem('AppID') + "&Limit=" + this.Limit)
                .then(response => {
                    this.Items = response.data;
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                })
        },
        loadMoreItems() {
            this.Limit += 5; // 增加 LIMIT 的次数，例如每次增加 5 条
            this.callMessage(); // 调用 callMessage 方法获取新的数据
        },
        goToSend() {
            this.$router.push('/send');
        }
    }
};

</script>